import React from "react";
import { Page, SkipToContent } from "@patternfly/react-core";

import Header from "../components/Header";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import {
	Grid,
	GridItem,
	Title,
	PageSection,
	Text,
	Card,
	CardBody,
	CardTitle,
	TextContent,
	Avatar,
} from "@patternfly/react-core";
import aboutusImage from "../assets/aboutus.webp";

export default function AboutUs() {
	const mainContainerId = "main-content";
	const pageSkipToContent = (
		<SkipToContent href={`#${mainContainerId}`}>Skip to content</SkipToContent>
	);

	const teamMembers = [
		{
			name: "Dr Boon",
			role: "CEO",
			bio: "Dr Boon is the visionary leader of our company.",
			image: "https://placehold.co/100x100",
		},
		{
			name: "Haowei Ruan",
			role: "CTO",
			bio: "Haowei is the technical brain behind our innovative solutions.",
			image: "https://placehold.co/100x100",
		},
		{
			name: "Chin",
			role: "Design Lead",
			bio: "Chin ensures everything we create is visually stunning.",
			image: "https://placehold.co/100x100",
		},
	];

	return (
		<Page
			header={<Header />}
			sidebar={<SideBar />}
			isManagedSidebar
			skipToContent={pageSkipToContent}
			mainContainerId={mainContainerId}
			style={{ backgroundColor: "#373A40" }}
		>
			<PageSection className="fancy-bg">
				<Grid
					hasGutter
					style={{
						maxWidth: "1080px",
						margin: "50px auto",
						width: "100%",
					}}
				>
					<GridItem span={12}>
						<Grid hasGutter>
							{/* Image Column */}
							<GridItem
								sm={12}
								md={6}
								order={{ default: 1, sm: 1, md: 2 }}
								className="image-column"
							>
								<img
									src={aboutusImage}
									alt="About Us"
									style={{
										minWidth: "280px",
										borderRadius: "5px", // Optional rounded corners
									}}
								/>
							</GridItem>

							{/* Text Column */}
							<GridItem
								sm={12}
								md={6}
								order={{ default: 2, sm: 2, md: 1 }}
								className="text-column"
							>
								<Title headingLevel="h1">About Us</Title>
								<TextContent>
									<Text>
										We are a team of passionate individuals dedicated to
										delivering the best products and services to our customers.
										Our mission is to innovate, inspire, and impact.
									</Text>
									<Text>
										At the heart of everything we do lies a commitment to
										excellence, creativity, and collaboration. We believe in
										pushing boundaries, exploring new possibilities, and staying
										ahead in an ever-evolving landscape. Our diverse team brings
										together a wealth of expertise and perspectives, enabling us
										to tackle challenges with ingenuity and adaptability.
									</Text>
									<Text>
										Together, we aim to build a future where innovation meets
										purpose, and every interaction leaves a lasting, positive
										impression.
									</Text>
								</TextContent>
							</GridItem>
						</Grid>
					</GridItem>

					<GridItem span={12} style={{ marginTop: "50px" }}>
						<Title headingLevel="h2">Our Team</Title>
						<Grid hasGutter>
							{teamMembers.map((member, index) => (
								<GridItem key={index} span={12} md={4}>
									<Card className="card-rounded">
										<CardTitle>
											<Avatar
												src={member.image}
												alt={`${member.name}'s Avatar`}
											/>
											<span style={{ marginLeft: "1rem" }}>{member.name}</span>
										</CardTitle>
										<CardBody>
											<TextContent>
												<Text component="h3">{member.role}</Text>
												<Text>{member.bio}</Text>
											</TextContent>
										</CardBody>
									</Card>
								</GridItem>
							))}
						</Grid>
					</GridItem>
				</Grid>
			</PageSection>

			<Footer />
		</Page>
	);
}
